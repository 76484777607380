<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />

    <div class=" section-padding">
      <div class="container default-padding">
        <div class="primary-heading text-center">
          {{ `Our ${labels.MENU_PROJECTS}` }}
        </div>

        <div class="caption mb-8">
          {{ labels.PH_SERVICES }}
        </div>

        <div v-if="!loading">
          <el-row v-if="firstChunk.length || secondChunk.length" :gutter="40">
            <el-col
              v-for="(chunk, key) in projectChunks"
              :key="key"
              :span="24"
              :md="12"
            >
              <el-collapse v-if="chunk.length" v-model="activeProjects">
                <el-collapse-item
                  v-for="(project, index) in chunk"
                  :key="project.id"
                  :name="project.id"
                >
                  <template #title>
                    {{
                      `${
                        projectChunks[parseInt(key) - 1]
                          ? projectChunks[parseInt(key) - 1].length + index + 1
                          : index + 1
                      }. ${project.name}`
                    }}
                  </template>
                  <div class="project">
                    <div>
                      <div class="heading">Description</div>
                      <div class="content">{{ project.description }}</div>
                    </div>
                    <div v-if="project.installation_date">
                      <div class="heading">Installation</div>
                      <div class="content">{{ project.installation_date }}</div>
                    </div>
                    <div v-if="project.reason">
                      <div class="heading">Reason</div>
                      <div class="content">{{ project.reason }}</div>
                    </div>
                    <div v-if="project.action">
                      <div class="heading">Action</div>
                      <div class="content">{{ project.action }}</div>
                    </div>
                    <div v-if="project.result">
                      <div class="heading">Result</div>
                      <div class="content">{{ project.result }}</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
          <div v-else class="text-center">No Projects found</div>
        </div>
        <div v-else>
          {{ `${labels.LBL_LOADING}...` }}
        </div>
      </div>
    </div>

    <get-a-quote />

    <horizontal-client-slider />
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";

  import BreadCrumb from "@/components/BreadCrumb.vue";
  import GetAQuote from "../components/GetAQuote.vue";
  import HorizontalClientSlider from "../components/HorizontalClientSlider.vue";

  export default {
    name: "Projects",

    components: {
      BreadCrumb,
      GetAQuote,
      HorizontalClientSlider,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "Projects", path: "" }],

        firstChunk: [],
        secondChunk: [],

        activeProjects: [],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        allProjects: (state) => state.project.projects.data,
      }),

      projectChunks() {
        return {
          0: this.firstChunk,
          1: this.secondChunk,
        };
      },
    },

    watch: {
      allProjects: {
        handler(nv) {
          if (nv.length) {
            this.chunkIntoTwo(nv);
          }
        },
      },

      firstChunk: {
        handler(nv) {
          this.activeProjects.push(nv[0].id);
        },
      },

      secondChunk: {
        handler(nv) {
          nv.length && this.activeProjects.push(nv[0].id);
        },
      },
    },

    created() {
      this.loadProjects();
    },

    methods: {
      loadProjects() {
        this.$store.dispatch("project/getAllProjects");
      },

      chunkIntoTwo(projects) {
        const middleIndex = Math.ceil(projects.length / 2);

        this.firstChunk = projects.splice(0, middleIndex);
        this.secondChunk = projects.splice(-middleIndex);
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";
  .el-collapse-item {
    &__header,
    &__content {
      font-size: 1rem;
      padding-bottom: 0;
    }
  }

  .project {
    padding: 0.5rem 1rem;
    border: 1px solid $background-color;
    border-bottom: none;
    // border-radius: 0.25rem;

    .heading {
      font-weight: 500;
    }

    .content {
      color: gray;
      margin-bottom: 1rem;

      &::before {
        content: "-";
        margin-right: 0.25rem;
      }
    }
  }

  // .service {
  //   padding: 3rem 0;

  //   min-height: 400px;

  //   &__image {
  //     height: 350px;
  //     width: 50%;
  //     margin-bottom: 1rem;
  //     object-fit: cover;
  //   }

  //   &__title {
  //     margin-top: -0.5rem;
  //     font-size: 1.625rem;
  //     color: $primary-color;
  //     text-transform: uppercase;
  //   }

  //   &:nth-child(even) {
  //     background: $background-color;
  //     .service__image {
  //       float: right;
  //       margin-left: 2rem;
  //     }
  //   }

  //   &:nth-child(odd) {
  //     .service__image {
  //       float: left;
  //       margin-right: 2rem;
  //     }
  //   }
  // }

  // .install-req {
  //   background: $background-color;
  //   padding: 2rem 0;
  // }

  // @media only screen and (max-device-width: 768px) {
  //   .service {
  //     &__title {
  //       text-align: center;
  //     }

  //     &__image {
  //       width: 100%;
  //       margin: 0;
  //     }
  //   }
  // }
</style>
